import { useState } from 'react';
import validator from 'validator';
import '../../css/contactform.css';

function ContactForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        telephone: '',
        subject: '',
        inquiry: [],
        message: ''
    });

  

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData(prevData => ({
                ...prevData,
                inquiry: checked
                    ? [...prevData.inquiry, value]
                    : prevData.inquiry.filter(inquiry => inquiry !== value)
            }));
        } else {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }
    };

    const validateForm = () => {
        const newErrors = {};
        const { name, email, telephone, subject, message } = formData;

        if (!name) newErrors.name = 'Name is required';
        if (!formData.email || !validator.isEmail(formData.email)) newErrors.email = 'Valid email is required';
        if (!telephone || !/^\d+$/.test(telephone)) newErrors.telephone = 'Telephone must be a number';
        if (!subject) newErrors.subject = 'Subject is required';
        if (!message) newErrors.message = 'Message is required';
        if (formData.inquiry.length === 0) newErrors.inquiry = 'Please select at least one inquiry type';
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const response = await fetch('http://localhost:5000/api/submit-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                alert('Form submitted successfully');
                setFormData({
                    name: '',
                    email: '',
                    telephone: '',
                    subject: '',
                    inquiry: [],
                    message: ''
                });
                setErrors({});
            } else {
                alert('Form submission failed');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Form submission failed');
        }
    };

    return (
        <main id='main'>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title bg-white text-center text-primary px-3">Contact Us</h6>
                        <h1 className="mb-5">Contact For Any Query</h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <h5>Get In Touch</h5>
                            <p className="mb-4">For any instant queries, please feel free to contact us. <a href="#">Download Now</a>.</p>
                            <div className="d-flex align-items-center mb-3">
                                <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{width: "50px", height: "50px"}}>
                                    <i className="fa fa-map-marker-alt text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h5 className="text-primary">Office</h5>
                                    <p className="mb-0">Sky Palace Building No. 802, 1-29-7 Takadanobaba, Shinjuku-ku, Tokyo</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-3">
                                <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{width: "50px", height: "50px"}}>
                                    <i className="fa fa-phone-alt text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h5 className="text-primary">Telephone</h5>
                                    <p className="mb-0">TEL 03-6709-6852 FAX 03-6709-6853</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{width: "50px", height: "50px"}}>
                                    <i className="fa fa-envelope-open text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h5 className="text-primary">Email</h5>
                                    <p className="mb-0">info@wizglobaljp.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="iframe-container">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.631592905606!2d139.70168031208942!3d35.71068247246351!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d97dc582071%3A0x65c9104c4e406edc!2s1-29-7%20sky%20palace%20building%20802!5e0!3m2!1sen!2snp!4v1722105730554!5m2!1sen!2snp"
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                    aria-hidden="false"
                                    tabIndex="0"
                                ></iframe>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                            <form onSubmit={handleSubmit}>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                id="name"
                                                name="name"
                                                placeholder="Your Name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="name">Your Name</label>
                                            {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="email"
                                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                id="email"
                                                name="email"
                                                placeholder="Your Email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="email">Your Email</label>
                                            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                                                id="telephone"
                                                name="telephone"
                                                placeholder="Telephone Number"
                                                value={formData.telephone}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="telephone">Telephone Number</label>
                                            {errors.telephone && <div className="invalid-feedback">{errors.telephone}</div>}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.subject ? 'is-invalid' : ''}`}
                                                id="subject"
                                                name="subject"
                                                placeholder="Subject"
                                                value={formData.subject}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="subject">Subject</label>
                                            {errors.subject && <div className="invalid-feedback">{errors.subject}</div>}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-section">
                                            <label>Contents of inquiry *</label><br/>
                                            <input
                                                type="checkbox"
                                                name="inquiry"
                                                value="About Class"
                                                checked={formData.inquiry.includes('About Class')}
                                                onChange={handleChange}
                                            /> About Class<br/>
                                            <input
                                                type="checkbox"
                                                name="inquiry"
                                                value="About online class"
                                                checked={formData.inquiry.includes('About online class')}
                                                onChange={handleChange}
                                            /> About online class<br/>
                                            <input
                                                type="checkbox"
                                                name="inquiry"
                                                value="About attending the seminar"
                                                checked={formData.inquiry.includes('About attending the seminar')}
                                                onChange={handleChange}
                                            /> About attending the seminar<br/>
                                            <input
                                                type="checkbox"
                                                name="inquiry"
                                                value="About Recruitment"
                                                checked={formData.inquiry.includes('About Recruitment')}
                                                onChange={handleChange}
                                            /> About Recruitment<br/>
                                            {errors.inquiry && <div className="invalid-feedback d-block">{errors.inquiry}</div>}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea
                                                className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                                                placeholder="Leave a message here"
                                                id="message"
                                                name="message"
                                                style={{height: "150px"}}
                                                value={formData.message}
                                                onChange={handleChange}
                                                required
                                            ></textarea>
                                            <label htmlFor="message">Message</label>
                                            {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default ContactForm;

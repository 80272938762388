import React, { useState, useEffect } from 'react';
import '../css/style.css';
import '../lib/animate/animate.min.css';

function Footer() {
    const [footerData, setFooterData] = useState({
        quickLinks: [],
        contactInfo: [],
        socialMediaLinks: [],
        galleryImages: [],
    });

    useEffect(() => {
        // Fetch data from the backend when the component is mounted
        const fetchFooterData = async () => {
            try {
                const response = await fetch('/api/footer');
                const data = await response.json();
                setFooterData(data);
            } catch (error) {
                console.error("Error fetching footer data:", error);
            }
        };

        fetchFooterData();
    }, []);

    return (
        <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Quick Link</h4>
                        {footerData.quickLinks.map((link, index) => (
                            <a key={index} className="btn btn-link" href={link.href}>{link.text}</a>
                        ))}
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Contact</h4>
                        {footerData.contactInfo.map((info, index) => (
                            <p key={index} className="mb-2">
                                <i className={`fa ${info.icon} me-3`}></i>{info.text}
                            </p>
                        ))}
                        <div className="d-flex pt-2">
                            {footerData.socialMediaLinks.map((link, index) => (
                                <a key={index} className="btn btn-outline-light btn-social" href={link.url}>
                                    <i className={link.icon}></i>
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Gallery</h4>
                        <div className="row g-2 pt-2">
                            {footerData.galleryImages.map((image, index) => (
                                <div key={index} className="col-4">
                                    <img className="img-fluid bg-light p-1" src={image} alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Newsletter</h4>
                        <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
                        <div className="position-relative mx-auto" style={{ maxWidth: "400px" }}>
                            <input className="form-control border-0 w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                            <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-end">
                            <div className="footer-menu">
                                <a href="#">Home</a>
                                <a href="#">Cookies</a>
                                <a href="#">Help</a>
                                <a href="#">FQAs</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
